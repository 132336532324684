import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FullLayoutComponent } from './components/full-layout/full-layout.component';
import { AuthGuard } from './auth.guard';
import { CommonService } from './services/common.service';
import { TaxonomyDashboardComponent } from './pages/taxonomy-dashboard/taxonomy-dashboard.component';

export const routes: Routes = [
  { path: 'conversation-insights', component: FullLayoutComponent, canActivate: [AuthGuard] },
  { path: 'taxonomy-dashboard', component: FullLayoutComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [CommonService],
  exports: [RouterModule],
})
export class AppRoutingModule { }
