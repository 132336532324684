import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'right-panel-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './right-panel-header.component.html',
  styleUrl: './right-panel-header.component.scss',
})
export class RightPanelHeaderComponent {
  @Input() headerTitle: string = 'Header Title';
  @Input() buttonText: string = 'Apply';
  @Input() disabled: boolean = false;
  @Output() onApply = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();

  handleApplyPress() {
    this.onApply.emit();
  }

  handleClosePress() {
    this.onClose.emit();
  }

  handleDeletePress() {
    this.onDelete.emit();
  }
}
