<div class="form-header-container">
  <div class="form-header">
    <div class="form-title">
      <span>{{headerTitle}}</span>
    </div>
    <div class="form-btn-container">
      <button class="btn-apply" [disabled]="disabled" [ngClass]="{'disabled': disabled}" (click)="handleApplyPress()">
        <span>{{buttonText}}</span>
      </button>
      <button class="btn-close" (click)="handleClosePress()">
        <img src="assets/icons/close-icon-grey.svg" alt="close">
      </button>
      <button class="btn-delete" (click)="handleDeletePress()">
        <img src="assets/icons/delete-icon-red.svg" alt="delete">
      </button>
    </div>
  </div>
</div>