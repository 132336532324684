import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'className',
  standalone: true,
})
export class ClassNamePipe implements PipeTransform {
  transform(baseClass: string, width: string): string {
    let widthClass = '';

    // Handle different width types and map them to respective class names
    switch (width) {
      case 'full-width':
        widthClass = 'full-width';
        break;
      case '400px':
        widthClass = 'width-400px';
        break;
      case '200px':
        widthClass = 'width-200px';
        break;
      case '300px':
        widthClass = 'width-300px';
        break;
      default:
        widthClass = ''; // In case of invalid or missing width type, no additional class is added
        break;
    }

    return `${widthClass}`.trim();
  }
}
