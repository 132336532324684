<div class="form-outer-container">
  <right-panel-header [disabled]="!this.entityForm.valid || (!applyButtonEnabled && !_intentUpdated())"
    headerTitle="Entity Details" (onApply)="onApply()" (onClose)="onClose()"
    (onDelete)="onDelete()"></right-panel-header>
  <div class="form-body">
    <div class="form-section-container">
      <!-- Entity Details Section -->
      <div class="section-1" [formGroup]="entityForm">
        <div class="section-title" (click)="toggleSectionOne($event)">
          <div class="title-left">
            <span>1</span>
            <span>Entity Details</span>
          </div>
          <div class="btn-dropdown">
            <button>
              <img class="accordion-arrow" [class.rotated]="!isSectionOneOpen" src="assets/icons/dropdown-icon.svg"
                alt="dropdown-icon">
            </button>
          </div>
        </div>
        <div class="section-1-content" *ngIf="isSectionOneOpen" [@contentAnimation]>
          <div class="field-container">
            <label for="type">Type <span class="required-star">*</span></label>
            <!-- <div class="select-wrapper"> -->
            <custom-select [selectedValue]="type" [contentList]="_typeContent" dropDownWidth="400px"
              (onItemSelect)="handleOperatorSelect($event)" [showSearch]="false" [showHeader]="false">
            </custom-select>
            <!-- </div> -->

          </div>
          <!-- Label Input -->
          <div class="field-container">
            <label for="label">Label <span class="required-star">*</span></label>
            <input type="text" id="label" formControlName="label" class="form-control" placeholder="Enter label" />
          </div>

          <!-- Description Textarea -->
          <div class="field-container">
            <label for="description">Description <span class="required-star">*</span></label>
            <textarea id="description" formControlName="description" class="form-control" rows="3"
              placeholder="Enter description"></textarea>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <!-- Intents Section -->
      <div class="section-2">
        <div class="section-title" (click)="toggleSectionTwo($event)">
          <div class="title-left">
            <span>2</span>
            <span>Intents</span>
          </div>
          <div class="btn-dropdown">
            <button>
              <img class="accordion-arrow" [class.rotated]="!isSectionTwoOpen" src="assets/icons/dropdown-icon.svg"
                alt="dropdown-icon">
            </button>
          </div>
        </div>
        <div class="section-2-content" *ngIf="isSectionTwoOpen" [@contentAnimation]>
          <label class="section-2-label">Intent List <span class="required-star">*</span></label>
          <div class="linked-items-container">
            <div class="linked-item-header" (click)="handleAddNewIntent()">
              <div class="liked-item-title">Add New Intent</div>
              <img *ngIf="!_newIntentForm() && (_editIntentId() == -1)" src="assets/icons/add-plus-green.svg"
                alt="add-icon">
            </div>
            <div class="linked-item-body">
              <intent-form *ngIf="_newIntentForm()" [selectedTaxonomyItem]="_selectedTaxonomyItem()"
                (onIntentFormClose)="handleCancelIntent()"
                (onIntentFormComplete)="handleIntentFormComplete()"></intent-form>
              <div class="linked-item-list"
                *ngFor="let item of _selectedTaxonomyItem().linked_items; let i = index">
                <intent-form *ngIf="_editIntentId() === i else showIntent "
                  [selectedTaxonomyItem]="_selectedTaxonomyItem()" (onIntentFormClose)="handleCancelEditIntent()"
                  (onIntentFormComplete)="handleIntentFormComplete()" [editId]="_editIntentId()"></intent-form>
                <ng-template #showIntent>
                  <div class="linked-item" *ngIf="_deleteIntentId() !== i; else deleteUI">
                    <div class="linked-item-title">{{ item.label }}</div>
                    <div class="linked-item-btn-container">
                      <button class="linked-item-btn btn-delete" (click)="handleDeleteIntent(i)">
                        <img src="assets/icons/delete_outline_white.svg" alt="delete">
                      </button>
                      <button *ngIf="!_newIntentForm()" class="linked-item-btn btn-edit" (click)="handleEditIntent(i)">
                        <img src="assets/icons/edit.svg" alt="edit">
                      </button>
                    </div>
                  </div>
                  <ng-template #deleteUI>
                    <div class="delete-intent-container">
                      <div class="delete-intent-left">
                        <img src="assets/icons/warning_amber.svg" alt="exclamation">
                        Delete Intent?
                      </div>
                      <div class="delete-intent-right">
                        <button class="delete-intent-btn btn-yes" (click)="handleDeleteIntentConfirm(item)">
                          <img src="assets/icons/check_green.svg" alt="delete">
                          Yes
                        </button>
                        <button class="delete-intent-btn btn-cancel" (click)="handleDeleteIntentCancel()">
                          <img src="assets/icons/close_red.svg" alt="delete">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>