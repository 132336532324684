import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { navbarData } from './navbar-tab';
@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, MatSidenavModule, MatTooltipModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  activeTab: string = 'conversation-insights';
  collapsed = true;
  navData = navbarData;

  @Output() collapsedChanged = new EventEmitter<boolean>();
  @Output() tabChanged = new EventEmitter<any>(); // Emit the tab change event

  onExpand() {
    this.collapsed = false;
    this.collapsedChanged.emit(this.collapsed);
  }

  onCollapse() {
    this.collapsed = true;
    this.collapsedChanged.emit(this.collapsed);
  }

  // Handle tab selection and emit the selected tab
  onTabSelect(tab: any) {
    this.activeTab = tab.routeLink;
    this.tabChanged.emit(tab); // Emit the selected tab to the parent
  }
}
