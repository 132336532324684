<div class="linked-item-form" [formGroup]="intentForm">
  <div class="form-group">
    <label for="label">Label <span class="required-star">*</span></label>
    <input type="text" id="label" formControlName="label" class="form-control" placeholder="Enter label" />
  </div>

  <div class="form-group">
    <label for="description">Description</label>
    <textarea id="description" formControlName="description" class="form-control" rows="3"
      placeholder="Enter description"></textarea>
  </div>

  <div class="action-buttons">
    <button class="btn-cancel" (click)="handleCancelIntent()">
      <span>Cancel</span>
    </button>
    <button class="btn-add-item" (click)="handleAddIntent()" [disabled]="!this.intentForm.valid"
      [ngClass]="{'disabled': !this.intentForm.valid}">
      <span>Add Intent</span>
    </button>
  </div>
</div>