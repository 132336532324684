export interface NavBarData {
  routeLink: string;
  icon: string;
  label: string;
}

export const navbarData: NavBarData[] = [
  {
    routeLink: 'conversation-insights',
    icon: '../../../../assets/icons/bar_chart_icon.svg',
    label: 'Conversation Insights',
  },
  {
    routeLink: 'taxonomy-dashboard',
    icon: '../../../../assets/icons/data_table.svg',
    label: 'Taxonomy Dashboard',
  },
];
