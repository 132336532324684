import { Component, computed, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CommonModule } from '@angular/common';

import { ActionService } from '../../../services/action.service';
import {
  DropdownObjectType,
  LinkedItem,
  LocalTaxonomy,
  TaxonomyEntityFormGroup,
  TaxonomyRequest,
} from '../../../shared/types';
import { TaxonomyService } from '../../../services/taxonomy.service';
import { CustomSelectComponent } from '../../../components/custom-select/custom-select.component';
import { ConfirmPopupService } from '../../../services/confirm-popup.service';
import { NotificationService } from '../../../services/notification.service';
import { PopupConfirmationData, TaxonomyTypes } from '../../../shared/constant';
import { IntentFormComponent } from './intent-form/intent-form.component';
import { RightPanelHeaderComponent } from '../../../components/right-panel-header/right-panel-header.component';
import { CommonService } from '../../../services/common.service';
import { delayLoading } from '../../../shared/appUtil';

@Component({
  selector: 'app-entity-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, CustomSelectComponent, IntentFormComponent, RightPanelHeaderComponent],
  templateUrl: './entity-form.component.html',
  styleUrl: './entity-form.component.scss',
  animations: [
    trigger('contentAnimation', [
      state(
        'void',
        style({
          maxHeight: '0',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        '*',
        style({
          maxHeight: '600px',
          opacity: 1,
        })
      ),
      transition('void <=> *', animate('300ms ease-in-out')),
    ]),
  ],
})
export class EntityFormComponent {
  constructor(
    private fb: FormBuilder,
    public actionsService: ActionService,
    private taxonomyService: TaxonomyService,
    private confirmPopupService: ConfirmPopupService,
    public notificationService: NotificationService,
    private commonService: CommonService
  ) {
    this.entityForm = this.fb.group({
      type: [TaxonomyTypes[0].value, Validators.required],
      label: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  _newIntentForm = signal<boolean>(false);
  _intentUpdated = signal<boolean>(false);
  _editIntentId = signal<number>(-1);
  _deleteIntentId = signal<number>(-1);
  _selectedTaxonomyItem = computed<LocalTaxonomy>(() => this.taxonomyService.taxonomyItem());
  _typeContent = TaxonomyTypes;
  originalFormValues: TaxonomyEntityFormGroup | undefined;
  applyButtonEnabled: boolean = false;
  entityForm: FormGroup;
  isSectionOneOpen = true;
  isSectionTwoOpen = true;

  ngOnInit() {
    if (this._selectedTaxonomyItem()) {
      this.entityForm.patchValue({
        type:
          this._selectedTaxonomyItem().type.length > 0 ? this._selectedTaxonomyItem().type : this._typeContent[0].value,
        label: this._selectedTaxonomyItem().label,
        description: this._selectedTaxonomyItem().description,
      });
    }

    // Save the original values of the form
    this.originalFormValues = { ...this.entityForm.value };

    // Subscribe to value changes of the form
    this.entityForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }

  // Check if any form values have changed
  checkFormChanges(): void {
    // Compare the current form values with the original values
    this.applyButtonEnabled = JSON.stringify(this.entityForm.value) !== JSON.stringify(this.originalFormValues);
  }

  toggleSectionTwo(event: MouseEvent) {
    this.isSectionTwoOpen = !this.isSectionTwoOpen;
    event.stopPropagation();
  }

  toggleSectionOne(event: MouseEvent) {
    this.isSectionOneOpen = !this.isSectionOneOpen;
    event.stopPropagation();
  }

  convertToTaxonomyRequest(input: any): TaxonomyRequest {
    return {
      label: input.label,
      type: input.type, // Use the `value` property from `type`
      description: input.description,
      linked_items_type: input.linked_items_type || 'Intents', // Default to an empty string if not provided
      linked_items: input.linked_items.map((item: any) => ({
        label: item.label,
        description: item.description,
      })),
    };
  }

  async onApply() {
    let tempItem = {
      ...this._selectedTaxonomyItem(),
      ...this.entityForm.value,
    };
    const updatedTax = this.convertToTaxonomyRequest(tempItem);

    if (tempItem.id === -1) {
      this.commonService.addTaxonomy(updatedTax).subscribe(() => {});
    } else {
      this.commonService.updateTaxonomy(updatedTax, this._selectedTaxonomyItem().uuid).subscribe((res) => {
        this.notificationService.success('Update item', 'Item updated');
      });
    }

    await delayLoading(1);

    this.taxonomyService.triggerTaxonomyUpdate();
    this.actionsService.closeAddEntityForm();
  }

  onClose() {
    if (this.entityForm.valid && (this.applyButtonEnabled || this._intentUpdated())) {
      this.confirmPopupService
        .openConfirmDialog(PopupConfirmationData.cancelTaxonomyConfirmation)
        .subscribe((result) => {
          if (result?.data?.confirm) {
            this.actionsService.closeAddEntityForm();
          }
        });
    } else {
      this.actionsService.closeAddEntityForm();
    }
  }

  onDelete() {
    if (this._selectedTaxonomyItem().id >= 0) {
      this.confirmPopupService
        .openConfirmDialog(PopupConfirmationData.deleteTaxonomyConfirmation)
        .subscribe((result) => {
          if (result?.data?.confirm) {
            this.commonService.deleteTaxonomy({ id: this._selectedTaxonomyItem().uuid }).subscribe(async (res) => {
              await delayLoading(1);
              this.taxonomyService.triggerTaxonomyUpdate();
              this.notificationService.success('Delete item', 'Item deleted');
            });
            this.actionsService.closeAddEntityForm();
          }
        });
    } else {
      this.actionsService.closeAddEntityForm();
    }
  }

  handleAddNewIntent() {
    this._newIntentForm.set(true);
  }

  handleCancelIntent() {
    this._newIntentForm.set(false);
  }

  handleIntentFormComplete() {
    this._intentUpdated.set(true);
    this._editIntentId.set(-1);
    this._newIntentForm.set(false);
  }

  handleCancelEditIntent() {
    this._editIntentId.set(-1);
  }

  handleEditIntent(index: number) {
    this._editIntentId.set(index);
  }

  handleDeleteIntent(index: number) {
    this._deleteIntentId.set(index);
  }

  handleDeleteIntentCancel() {
    this._deleteIntentId.set(-1);
  }

  handleDeleteIntentConfirm(item: LinkedItem) {
    if (this._selectedTaxonomyItem().linked_items) {
      this._selectedTaxonomyItem()?.linked_items?.splice(this._deleteIntentId(), 1);
      this._intentUpdated.set(true);
    }
    this.handleDeleteIntentCancel();
  }

  get type(): DropdownObjectType {
    return {
      value: this.entityForm.get('type')?.value,
      label: this.entityForm.get('type')?.value,
    } as DropdownObjectType;
  }

  handleOperatorSelect(value: DropdownObjectType) {
    this.entityForm.patchValue({
      type: value.value,
    });
  }
}
