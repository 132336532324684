<div class="entity-dropdown-container">
  <div class="entity-button" [matMenuTriggerFor]="dropdownMenu" (click)="handleButtonClick($event)">
    {{selectedValue?.label }}
    <div class="button-icon">
      <img src="assets/icons/keyboard_arrow_down_new.svg">
    </div>
  </div>

  <mat-menu [class]="'width-300px'" #dropdownMenu="matMenu" xPosition="before" yPosition="below"
    (closed)="onMatMenuClosed()">
    <div class="list-header">
      Select Entity Type
    </div>

    <div class="list-wrapper">
      <div class="list-item" *ngFor="let item of contentList" (click)="handleItemSelect($event, item)">
        <div class="list-item-wrapper">
          <div class="item-bullet">
            <img *ngIf="item.value !== selectedValue?.value;else selected" class="empty"
              src="assets/icons/radio_empty.svg">
            <ng-template #selected>
              <img class="selected" src="assets/icons/radio_selected.svg">
            </ng-template>
          </div>
          {{item.label}}
        </div>

      </div>
    </div>
  </mat-menu>
</div>