import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ToggleComponent } from '../toggle/toggle.component';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CommonService } from '../../services/common.service';
import { FilterService } from '../../services/filter.service';
import { LabelService } from '../../services/label.service';
import { Filters, LabelTypes } from '../../shared/label.types';
import { CustomInputType } from '../../shared/types';
import { MatMenuModule } from '@angular/material/menu';
import { ClassNamePipe } from '../../pipes/class-name.pipe';

@Component({
  selector: 'multi-selector',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, FormsModule, ToggleComponent, MatMenuModule, ClassNamePipe],
  templateUrl: './multi-selector.component.html',
  styleUrl: './multi-selector.component.scss',
})
export class MultiSelectorComponent {
  constructor(
    public commonService: CommonService,
    public filterService: FilterService,
    private labelService: LabelService
  ) {}

  @Input() inputText: string | string[] = [''];
  @Input() openAbove: boolean = false;
  @Output() onInputChange = new EventEmitter<string[]>();
  @Input() columnName: string = '';
  @Input() customInputType: CustomInputType = CustomInputType.string;
  @Input() dropDownWidth: string = '300px';

  isOptionSelected = false;
  dropdownOpened: boolean = false;
  searchQuery: string = '';
  selectedOptions: string[] = [];
  primaryTopics: { label: string; tempIsSelected: boolean }[] = [];
  filteredCount: number = 0;
  topicsLabels: Filters = {} as Filters;
  selectedOptionText: string = this.topicsLabels.select_an_option;

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.topicsLabels = data?.filter ?? {};
    });

    this.filterService.getAllFilters().subscribe((filters) => {
      // Define a mapping from column names to filter keys
      const filterKeyMap: any = {
        PrimaryTopic: 'primarytopic',
        Topics: 'TopicsClustered',
        Intent: 'intent',
        Agent_Username: 'agent_username',
        Queue_Name: 'queue_name',
      };

      // Extract filter key from the map
      const filterKey = filterKeyMap[this.columnName] ?? '';

      // Process the filter list based on the filter key
      let filterListPrimaryTopics: string[] = (filters.filters[filterKey] as string[]) ?? [''];

      this.primaryTopics = filterListPrimaryTopics
        .filter((topic) => topic.trim() !== '')
        .map((topic) => ({
          label: topic,
          tempIsSelected: this.inputText.includes(topic),
        }));

      // Common post-processing functions
      this.updateSelectedOptions();
      this.updateFiltersWithPrimaryTopics();
    });
  }

  updateFiltersWithPrimaryTopics(): void {}

  handleButtonClick(event: Event): void {
    this.dropdownOpened = !this.dropdownOpened;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  clearSearch(): void {
    this.searchQuery = '';
  }

  onMatMenuClosed() {}

  onOutsideClick(): void {
    this.dropdownOpened = false;
  }

  handleItemSelect(item: { label: string; tempIsSelected: boolean }): void {
    item.tempIsSelected = !item.tempIsSelected;
    this.updateSelectedOptions();
  }

  updateSelectedOptions(): void {
    this.selectedOptions = this.primaryTopics.filter((f) => f.tempIsSelected).map((f) => f.label);
    if (this.onInputChange) {
      this.onInputChange.emit(this.selectedOptions);
    }
    this.updateSelectedOptionText();
  }

  onValueChange(state: 'on' | 'off', item: { label: string; tempIsSelected: boolean }): void {
    item.tempIsSelected = state === 'on';
    this.updateSelectedOptions();
  }

  updateSelectedOptionText(): void {
    const selectedCount = this.selectedOptions.length;
    if (selectedCount === 0) {
      this.selectedOptionText = 'Select an option';
      this.isOptionSelected = false;
    } else if (selectedCount === 1) {
      this.selectedOptionText = this.selectedOptions[0];
      this.isOptionSelected = true;
    } else {
      this.selectedOptionText = `${selectedCount} options selected`;
      this.isOptionSelected = true;
    }
  }

  get filteredTopics() {
    const query = this.searchQuery.trim().toLowerCase();

    // Filter topics based on the search query
    let filtered = this.primaryTopics.filter((topic) => topic.label.toLowerCase().indexOf(query) !== -1);

    // Sort topics to bring selected topics to the top
    filtered.sort((a, b) => {
      // If a is selected and b is not, a should come first
      if (a.tempIsSelected && !b.tempIsSelected) {
        return -1;
      }
      // If b is selected and a is not, b should come first
      if (b.tempIsSelected && !a.tempIsSelected) {
        return 1;
      }
      // If both are selected or both are not selected, retain original order
      return 0;
    });

    return { topics: filtered, count: filtered.length };
  }

  get getPrimaryTopics() {
    return this.filteredTopics.topics;
  }

  get topicCount() {
    return this.filteredTopics.count;
  }
}
