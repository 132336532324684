import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { NavbarComponent } from './components/navbar/navbar.component';
import { SkeletonLoaderComponent } from './pages/dashboard/skeleton-loader/skeleton-loader.component';
import { AuthService } from './auth.service';
import { HeaderComponent } from './components/header/header.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, NavbarComponent, SkeletonLoaderComponent, SnackBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private authService: AuthService) { }
  title = 'rozie-care-insights-dashboard';

  ngOnInit() {
    this.authService.handleAuthCallback();
  }
}
