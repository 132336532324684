import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { ActionService } from '../../services/action.service';
import { EntityFormComponent } from './entity-form/entity-form.component';
import { EmptySelectionTaxonomy, TaxonomyFilterContent } from '../../shared/constant';
import { DropdownObjectType, LocalTaxonomy } from '../../shared/types';
import { TaxonomyService } from '../../services/taxonomy.service';
import { EntityDropdownComponent } from './entity-dropdown/entity-dropdown.component';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-taxonomy-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule, EntityFormComponent, MatExpansionModule, EntityDropdownComponent],
  templateUrl: './taxonomy-dashboard.component.html',
  styleUrl: './taxonomy-dashboard.component.scss',
  animations: [
    trigger('contentAnimation', [
      state(
        'void',
        style({
          maxHeight: '0',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        '*',
        style({
          maxHeight: '300px',
          opacity: 1,
        })
      ),
      transition('void <=> *', animate('300ms ease-in-out')),
    ]),
  ],
})
export class TaxonomyDashboardComponent {
  constructor(
    public actionsService: ActionService,
    private taxonomyService: TaxonomyService,
    private commonService: CommonService
  ) {}

  _selectedFilter = signal<DropdownObjectType>(TaxonomyFilterContent[0]);
  _taxonomyCards = computed<LocalTaxonomy[]>(() => this.taxonomyService.taxonomyData());
  _filterContent = signal<Array<DropdownObjectType>>(TaxonomyFilterContent);
  _searchText = signal<string>('');
  isOpen = false;

  ngOnInit() {
    // Trigger the getTaxonomy method when the update/delete event occurs
    this.taxonomyService.updateTaxonomy$.subscribe(() => {
      this.loadTaxonomy();
    });
  }

  loadTaxonomy() {
    this.commonService.getTaxonomy({}).subscribe((res) => {
      this.taxonomyService.setTaxonomyData(res.taxonomy);
      // Create a Set to keep track of unique values based on 'value'
      const content: DropdownObjectType[] = res.taxonomy
        .map((item) => ({ label: item.type, value: item.type })) // Map to create the content array
        .filter(
          (
            value,
            index,
            self // Filter duplicates based on 'value'
          ) => index === self.findIndex((t) => t.value === value.value) // Compare by 'value'
        );

      this._filterContent.set([
        {
          label: 'All Entities',
          value: 'all',
        },
        ...content,
      ]);
    });
  }

  getTaxonomyCards = () => {
    let data = this._taxonomyCards();
    if (this._selectedFilter().value !== TaxonomyFilterContent[0].value) {
      data = data.filter((i) => i.type === this._selectedFilter().value);
    }

    if (this._searchText().trim()) {
      data = data.filter((i) => i.label.toLowerCase().includes(this._searchText().trim().toLowerCase()));
    }

    return data;
  };

  onSearchChange(event: Event) {
    const el = event.target as HTMLInputElement;
    this._searchText.set(el.value);
  }

  handleFilterSelect(filter: DropdownObjectType) {
    this._selectedFilter.set(filter);
  }

  handleCreateEntity() {
    this.taxonomyService.setTaxonomyItem({ ...EmptySelectionTaxonomy });
    this.actionsService.openAddEntityForm();
  }

  handleCardSelect(cardData: LocalTaxonomy) {
    this.taxonomyService.setTaxonomyItem(cardData);
    this.actionsService.openAddEntityForm();
  }

  toggle(event: MouseEvent) {
    this.isOpen = !this.isOpen;
    event.stopPropagation();
  }

  onOverlayPress() {
    this.taxonomyService.setTaxonomyItem({ ...EmptySelectionTaxonomy });
    this.actionsService.closeAddEntityForm();
  }

  getTypeClass(typeValue: string): string {
    switch (typeValue) {
      case 'Primary Topic':
        return 'type-1-style';
      case 'Intents':
        return 'type-2-style';
      case 'Outcome':
        return 'type-3-style';
      default:
        return '';
    }
  }
}
