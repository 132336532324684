import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LocalTaxonomy, Taxonomy } from '../shared/types';
import {  EmptySelectionTaxonomy } from '../shared/constant';

@Injectable({
  providedIn: 'root',
})
export class TaxonomyService {
  private updateTaxonomySource = new BehaviorSubject<void>(undefined);
  private _selectedTaxonomyItem = signal<LocalTaxonomy>({ ...EmptySelectionTaxonomy });
  private _taxonomyData = signal<LocalTaxonomy[]>([]);
  private _editIndex: number = -1;
  updateTaxonomy$ = this.updateTaxonomySource.asObservable();

  constructor() {}

  // Method to trigger the update event
  triggerTaxonomyUpdate() {
    this.updateTaxonomySource.next(); // Notify subscribers
  }

  public get taxonomyItem(): WritableSignal<LocalTaxonomy> {
    return this._selectedTaxonomyItem;
  }

  setTaxonomyItem(data: LocalTaxonomy): void {
    this._selectedTaxonomyItem.set(data);
    this._editIndex = this._taxonomyData().findIndex((obj) => obj.id == data.id);
  }

  public get taxonomyData(): WritableSignal<LocalTaxonomy[]> {
    return this._taxonomyData;
  }

  setTaxonomyData(data: Taxonomy[]): void {
    const dataWIthIndex = data.map((item, index) => ({ id: index + 1, ...item }));
    this._taxonomyData.set(dataWIthIndex);
  }

  saveTaxonomyData(data: LocalTaxonomy) {
    const newData = [...this._taxonomyData(), data];
    this.setTaxonomyData(newData);
  }
}
