import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownObjectType } from '../../../shared/types';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'entity-dropdown',
  standalone: true,
  imports: [CommonModule, MatMenuModule],
  templateUrl: './entity-dropdown.component.html',
  styleUrl: './entity-dropdown.component.scss',
})
export class EntityDropdownComponent {
  @Input() selectedValue: DropdownObjectType | undefined | null = { label: '', value: '' };
  @Input() contentList: Array<DropdownObjectType> = [];
  @Output() onItemSelect = new EventEmitter<DropdownObjectType>();

  dropdownOpened: boolean = false;

  handleItemSelect = (event: MouseEvent, item: DropdownObjectType) => {
    if (this.onItemSelect) {
      this.onItemSelect.emit(item);
    }
  };

  onMatMenuClosed() {}

  handleButtonClick = (event: MouseEvent) => {
    this.dropdownOpened = !this.dropdownOpened;
  };
}
