<div class="table-container">
  <ngx-datatable *ngIf="!loaderFlag" [rows]="rows" [columns]="columns" [columnMode]="ColumnMode.force"
    class="material server-scrolling-demo table-class" [footerHeight]="32" [headerHeight]="headerHeight"
    [rowHeight]="rowHeight" [loadingIndicator]="isLoading" [ghostLoadingIndicator]="isLoading" headerClass="is-gender"
    [scrollbarV]="true" (scroll)="onScroll($event.offsetY)" [scrollbarH]="true">
    <ngx-datatable-column name="#" [width]="60" [maxWidth]="200">
      <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <div class="row-index">
          {{ rowIndex + 1 }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngFor="let col of columns; trackBy: trackByFn; index as idx" [name]="col.name ?? ''"
      [width]="col.prop === 'Topics' ? 640 : 320" [prop]="col?.prop ?? ''" (columnReorder)="onColumnReorder($event)">
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
        <!-- Custom template for "Topics" column -->
        <div *ngIf="col.prop === 'Topics'; else otherColumns">
          <div class="topics-container1">
            <div *ngIf="rows[rowIndex][col?.prop ?? ''] && rows[rowIndex][col?.prop ?? ''].length > 0"
              class="topics-container">
              <ng-container *ngFor="let topic of rows[rowIndex][col?.prop ?? '']; let i = index">
                <span [ngClass]="topic === 'NA' || topic === '' ? 'default-topic-class' : (i | topicClass)">
                  {{ topic | truncateTopic }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Fallback to handle other columns -->
        <ng-template #otherColumns>

          <!-- Handle Date column with formatting -->
          <div *ngIf="col.prop === 'Date'; else checkAgentConnectedToAgentTimestamp">
            {{ row[col.prop] | formatDate }}
          </div>

          <!-- Handle Agent_ConnectedToAgentTimestamp column with formatting -->
          <ng-template #checkAgentConnectedToAgentTimestamp>
            <div
              *ngIf="(col.prop === 'Agent_ConnectedToAgentTimestamp' || col.prop === 'ContactTimestamp' || col.prop === 'PNRCreationDate' || col.prop === 'UpcomingFlightDate'); else checkAgentInteractionDuration">
              {{ (row[col.prop] && row[col.prop] !== 'NA' && row[col.prop] !== 'null' && row[col.prop] !== '') ?
              (row[col.prop] | formatDate) : 'NA' }}
            </div>
          </ng-template>

          <ng-template #checkAgentInteractionDuration>
            <div *ngIf="col.prop === 'Agent_AgentInteractionDuration'; else checkHoldDuration">
              {{ row[col.prop] && row[col.prop] !== 'NA' ? (row[col.prop] | formatTimestamp) : 'NA' }}
            </div>
          </ng-template>


          <ng-template #checkHoldDuration>
            <div *ngIf="col.prop === '  Agent_CustomerHoldDuration'; else checkForSentiment">
              {{ row[col.prop] && row[col.prop] !== 'NA' ? (row[col.prop] | formatTimestamp) : 'NA' }}
            </div>
          </ng-template>

          <!-- Handle Customer Sentiment columns -->
          <ng-template #checkForSentiment>
            <div *ngIf="
                (col?.prop ?? '') === 'CustomerSentimentAtEnd' || (col?.prop ?? '') === 'CustomerSentimentAtStart';
                else handleLongTextWithTooltip
              ">
              <div [ngClass]="row[col?.prop ?? ''] | sentimentClass">
                {{ row[col?.prop ?? ''] | sentimentText }}
              </div>
            </div>
          </ng-template>

          <!-- Handle long text with tooltip -->
          <ng-template #handleLongTextWithTooltip>
            <div *ngIf="
                row[col?.prop ?? '']?.toString()?.length > 25 && col.prop != 'Date' && col.prop != 'Topics';
                else simpleText
              ">
              <div matTooltip="{{ row[col?.prop ?? ''] }}">
                {{ row[col?.prop ?? '']?.toString() | slice: 0 : 25
                }}{{ row[col?.prop ?? '']?.toString().length > 25 ? '...' : '' }}
              </div>
            </div>

            <!-- Default simple text handler -->
            <ng-template #simpleText>
              <div class="log-cell">
                <span>{{ row[col?.prop ?? ''] || 'NA'}}</span>

                <!-- Copy to Clipboard button (always visible) -->
                <!-- <button mat-icon-button class="copy-button" matTooltip="Copy to Clipboard"
                  (click)="copyToClipboard(row[col?.prop ?? ''] | formattedValue: col.prop)">
                  <mat-icon class="content-copy-icon">content_copy</mat-icon>
                </button> -->
              </div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer *ngIf="true">
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
        <div class="row-count">
          <div>{{ rowCount }} Records</div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <!-- Loader -->
  <div *ngIf="loaderFlag" class="main-view-container-loader">
    <img [src]="'assets/icons/loading.svg'">
    <div style="margin-left: 8px;">Loading...</div>
  </div>

