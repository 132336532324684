import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '../../services/cognito.service';
import { LabelService } from '../../services/label.service';
import { DataExplorerComponent } from '../../pages/dashboard/data-explorer/data-explorer.component';
import { OutsideClickDirective } from '../../directives/outside-click.directive';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [DataExplorerComponent, OutsideClickDirective],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  @Input() title: string = '';
  header: any = {};
  showDropdown: boolean = false;
  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    public labelService: LabelService
  ) { }

  ngOnInit(): void {
    this.labelService.getLabelData().subscribe((data) => {
      this.header = data?.header ?? {};
    });
  }

  clickLogout() {
    this.router.navigate(['login']);
    this.showDropdown = false;
    localStorage.clear();
    this.cognitoService.deleteCookies();
    this.cognitoService.clearCookies();
    localStorage.removeItem('authToken');
  }
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
  onOutsideClick = () => {
    this.showDropdown = false;
  };
}