import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavbarComponent } from '../navbar/navbar.component';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RightSidebarComponent } from '../../pages/dashboard/right-sidebar/right-sidebar.component';
import { HeaderComponent } from '../header/header.component';
import { TaxonomyDashboardComponent } from '../../pages/taxonomy-dashboard/taxonomy-dashboard.component';
import { NavBarData } from '../navbar/navbar-tab';

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    HeaderComponent,
    NavbarComponent,
    DashboardComponent,
    CommonModule,
    HttpClientModule,
    RightSidebarComponent,
  ],
  templateUrl: './full-layout.component.html',
  styleUrl: './full-layout.component.scss',
})
export class FullLayoutComponent {
  collapsed: boolean = true;
  dynamicTitle: string = 'Insights Dashboard';

  // Define your tabs here
  tabs = [
    { title: 'Insights Dashboard', component: DashboardComponent, routeLink: 'conversation-insights' },
    { title: 'Taxonomy Dashboard', component: TaxonomyDashboardComponent, routeLink: 'taxonomy-dashboard' },
  ];

  selectedTab: any = this.tabs[0];

  onCollapsedChanged(collapsed: boolean) {
    this.collapsed = collapsed;
  }

  // Handle tab change and update selected tab and title
  onTabChanged(tab: any) {
    this.selectedTab = tab;
    this.dynamicTitle = tab.title; // Update the title dynamically
  }

  handleTabChange(data: NavBarData) {
    switch (data.routeLink) {
      case 'conversation-insights':
        this.onTabChanged(this.tabs[0]);
        break;

      case 'taxonomy-dashboard':
        this.onTabChanged(this.tabs[1]);
        break;

      default:
        break;
    }
  }
}
