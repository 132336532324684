import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { FilterService } from '../../../services/filter.service';

@Component({
  selector: 'filter-button',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss',
})
export class FilterButtonComponent {
  constructor(private filterService: FilterService) {}

  @Input() buttonTitle: string = '';
  @Input() _dropdownOpened: boolean = false;
  @Output() onButtonClick = new EventEmitter<void>();

  protected _updateButtonStyle = signal<boolean>(false);

  ngOnInit() {
    this.filterService.getFilterAppliedStatus().subscribe((status) => {
      const tempData = this.filterService.getFilterConditionDataValue();
      if (status) {
        this.buttonTitle = `${tempData.length} ${tempData.length > 1 ? 'Filters' : 'Filter'} Applied`;
        this._updateButtonStyle.set(true);
      } else {
        this.buttonTitle = 'Filter';
        this._updateButtonStyle.set(false);
      }
    });
  }

  toggleDropdown() {
    this.onButtonClick.emit();
  }
}
