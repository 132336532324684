<div class="outer-container">
  <div class="table-container">
    <div class="table-header-container">
      <div class="left-section">
        <div class="entity-dropdown">
          <entity-dropdown [contentList]="_filterContent()" [selectedValue]="_selectedFilter()"
            (onItemSelect)="handleFilterSelect($event)"></entity-dropdown>
        </div>
      </div>
      <div class="right-section">
        <div class="searchbar-container">
          <img src="assets/icons/search-icon-grey.svg" alt="search-icon">
          <input placeholder="Search Entities" type="text" (input)="onSearchChange($event)">
        </div>
        <div class="btn-container">
          <button class="btn-create-entity" (click)="handleCreateEntity()">
            <img src="assets/icons/add-white.svg" alt="add-icon">
            <span>Create Entity</span>
          </button>
        </div>
      </div>
    </div>

    <div class="table-body-container">
      <div *ngFor="let card of getTaxonomyCards()" class="taxonomy-card-container" (click)="handleCardSelect(card)">
        <div class="taxo-header">
          <div class="taxo-name">{{ card.label }}</div>
          <div class="taxo-type" [ngClass]="getTypeClass(card.type)">
            <span>{{ card.type }}</span>
          </div>
        </div>
        <div class="taxo-description">
          <span>{{ card.description }}</span>
        </div>

        <!-- accordion.component.html -->
        <div class="accordion-container" *ngIf="card.linked_items && card.linked_items.length > 0">
          <div class="accordion-header" (click)="toggle($event)">
            <span class="accordion-title">Intents</span>
            <img class="accordion-arrow" [class.rotated]="isOpen" src="../../assets/icons/dropdown-grey.svg" alt="Open">
          </div>
          <div class="accordion-content" *ngIf="isOpen" [@contentAnimation]>
            <div *ngFor="let linkedCard of card.linked_items" class="linked-taxo-cards">
              <span>{{ linkedCard.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="chatbox-main-container">
    <app-right-sidebar (valueChange)="onValueChange($event)"></app-right-sidebar>
  </div> -->
</div>

<div class="overlay" (click)="onOverlayPress()" *ngIf="actionsService.displayForm">
</div>

<div class="form-popup" *ngIf="actionsService.displayForm">
  <app-entity-form></app-entity-form>
</div>