<div class="app-container">
  <div class="top-bar">
    <div class="bar-left-content">
      <div class="title">
        <!-- {{header.insights_title}} -->
        {{ title }} 
      </div>
    </div>
    <div class="menu-icon">
      <div class="icon">
        <div class="notification-icon">
          <img class="notification" src="assets/icons/notifications.svg" alt="icon2">
        </div>
        <div class="profile-icon-wrapper" (click)="toggleDropdown()">
          <img src="assets/icons/profile-icon.svg" alt="icon3" class="profile-icon" />
          @if (showDropdown) {
          <div class="dropdown" (click)="toggleDropdown()" appOutSideClick (outSideClick)="onOutsideClick()"
            (outSideClick_v2)="toggleDropdown()">
            <li (click)="clickLogout() "><img src="assets/icons/save_alt.svg" alt="logout icon"
                class="logout-icon" />Log Out</li>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>