import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { LocalTaxonomy } from '../../../../shared/types';
import { EmptySelectionTaxonomy } from '../../../../shared/constant';

@Component({
  selector: 'intent-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './intent-form.component.html',
  styleUrl: './intent-form.component.scss',
})
export class IntentFormComponent {
  constructor(private formBuilder: FormBuilder) {
    this.intentForm = this.formBuilder.group({
      label: ['', Validators.required],
      description: [''],
    });
  }

  @Input() selectedTaxonomyItem: LocalTaxonomy = { ...EmptySelectionTaxonomy };
  @Input() editId: number | undefined;
  @Output() onIntentFormClose = new EventEmitter<void>();
  @Output() onIntentFormComplete = new EventEmitter<void>();

  intentForm: FormGroup;

  ngOnInit() {
    if (this.editId != undefined && this.selectedTaxonomyItem.linked_items) {
      const intent = this.selectedTaxonomyItem.linked_items[this.editId];
      this.intentForm.patchValue({
        label: intent.label,
        description: intent.description,
      });
    }
  }

  handleCancelIntent() {
    this.onIntentFormClose.emit();
  }

  handleAddIntent() {
    if (this.editId != undefined && this.selectedTaxonomyItem.linked_items) {
      this.selectedTaxonomyItem.linked_items[this.editId] = {
        ...this.intentForm.value,
      };
    } else {
      const previousIntents = this.selectedTaxonomyItem.linked_items ?? [];
      const tempIntents = [...previousIntents, { ...this.intentForm.value }];

      this.selectedTaxonomyItem.linked_items = tempIntents;
    }
    this.onIntentFormComplete.emit();
  }
}
