<section class="navbar-section" [ngStyle]="{'width': collapsed ? '70px' : '230px'}">
  <div class="rozie-nav-container" [ngStyle]="{'width': collapsed ? '70px' : '230px'}">
    <div class="rozie-icon">
      <a class="pointer" routerLink="/conversation-insights" routerLinkActive="active" ariaCurrentWhenActive="page">
        <img src="assets/icons/Rozie_Icon_White 1.svg" alt="logo" />
      </a>
      <div class="rozie-icon-text" *ngIf="!collapsed">
        <img src="assets/icons/rozie_logo_text.svg" alt="logo-text" />
      </div>
    </div>
  </div>
  <div class="menu-container">
    <div class="menu-tabs">
      <div class="tab-container-wrapper" *ngFor="let data of navData">
        <a class="tab-container-navbar" [routerLink]="'/' + data.routeLink" (click)="onTabSelect(data)">
          <img [src]="data.icon" alt="tab" class="active-tab" matTooltip="{{data.label}}" matTooltipPosition="right">
          <span *ngIf="!collapsed">{{data.label}}</span>
        </a>
      </div>
    </div>
    <div class="expand-option" *ngIf="collapsed" (click)="onExpand()">
      <img src="assets/icons/arrow_back.svg" alt="expand">
    </div>
    <div class="collapse-option" *ngIf="!collapsed" (click)="onCollapse()">
      <img src="assets/icons/arrow_collapse.svg" alt="collapse">
    </div>
  </div>
</section>