import { Component, computed, EventEmitter, Output, signal, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedDataService } from '../../../services/shared-data.service';
import { TabularViewComponent } from '../tabular-view/tabular-view.component';
import { Dashboard, LabelTypes } from '../../../shared/label.types';
import { LabelService } from '../../../services/label.service';
import { OutsideClickDirective } from '../../../directives/outside-click.directive';
import { ButtonComponent } from '../../../components/button/button.component';
import * as Types from '../../../shared/types';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-search-dropdown',
  standalone: true,
  imports: [ButtonComponent, TabularViewComponent, OutsideClickDirective, CommonModule, FormsModule],
  templateUrl: './search-dropdown.component.html',
  styleUrl: './search-dropdown.component.scss',
})
export class SearchDropdownComponent {
  constructor(
    private sharedDataService: SharedDataService,
    public labelService: LabelService,
    private commonService: CommonService
  ) {}

  @Output() handleOnDownload = new EventEmitter<void>();
  @Output() searchTriggered = new EventEmitter<string>();
  @Output() ResetTriggered = new EventEmitter<string>();

  protected _dropdownOpened = signal<boolean>(false);
  dashboard: Dashboard = {} as Dashboard;
  isOptionSelected = false;
  searchButtonActive: boolean = false;
  searchQuery: string = '';
  startAPIDate: string | undefined;
  endAPIDate: string | undefined;
  searchBody: Types.TableViewBody = {} as Types.TableViewBody;
  searchResults: any[] = [];
  totalCount: number = 0;

  ngOnInit() {
    this.sharedDataService.getToggleValues().subscribe((values) => {
      this._dropdownOpened.set(values.search);
    });
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.dashboard = data?.dashboard ?? {};
    });
    this.sharedDataService.getDateRange().subscribe((date) => {
      this.startAPIDate = date.startAPIDate;
      this.endAPIDate = date.endAPIDate;
    });

    this.sharedDataService.totalCount$.subscribe((count) => {
      this.totalCount = count;
    });
  }

  toggleSearchDropdown(event: MouseEvent) {
    this.sharedDataService.toggleSearch(!this._dropdownOpened());
    event?.stopPropagation();
  }

  onOutsideClick = () => {
    this.sharedDataService.toggleSearch(false);
  };

  resetSearch() {
    this.searchQuery = '';
    this.sharedDataService.updateTotalCount(0);
    this.sharedDataService.setSearchTerm(this.searchQuery);
  }

  handleSearch() {
    if (this.searchQuery) {
      this.searchTriggered.emit();
      this.sharedDataService.setSearchTerm(this.searchQuery);
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.searchResults = [];
    this.sharedDataService.toggleSearch(false);
    this.sharedDataService.setSearchTerm(this.searchQuery);
  }

  stopPropagation = (event: MouseEvent) => {
    event?.stopPropagation();
  };
}
