import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor() { }

  displayForm: boolean = false;

  openAddEntityForm() {
    this.displayForm = true;
  }

  closeAddEntityForm() {
    this.displayForm = false;
  }
}
